import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import Layout from './components/Layout';

// Convert regular imports to lazy imports
const Home = lazy(() => import('./components/Home'));
const Institutions = lazy(() => import('./components/Institutions'));
const About = lazy(() => import('./components/About'));
const Contact = lazy(() => import('./components/Contact'));
const InstitutionEdit = lazy(() => import('./components/InstitutionEdit'));
const RackTypes = lazy(() => import('./components/RackTypes'));
const Containers = lazy(() => import('./components/Containers'));
const Users = lazy(() => import('./components/Users'));
const Racks = lazy(() => import('./components/Racks'));
const RackView = lazy(() => import('./components/RackView'));
const LockedRacks = lazy(() => import('./components/LockedRacks'));
const Usage = lazy(() => import('./components/Usage'));
const Specimens = lazy(() => import('./components/Specimens'));
const Activity = lazy(() => import('./components/Activity'));

function App() {
  return (
    <Router>
      <Layout>
        <div className="toc">
            <div className="menu-section">
              <Link to="/">Admin Home</Link>
            </div>

            <div className="menu-section">
              <Link to="http://app.tubetracker.com/login.asp">Production</Link>
              <Link to="http://test.tubetracker.com/">Testing</Link>
            </div>

            <div className="menu-section">
              <Link to="/activity">Activity</Link>
              <Link to="/locked-racks">Locked Racks</Link>
              <Link to="/usage">Usage</Link>
            </div>
        </div>
        <div className="content">
          <Suspense fallback={
            <div className="loading-spinner">
              Loading...
            </div>
          }>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/institutions/:id" element={<Institutions />} />
              <Route path="/institutions/:id/edit" element={<InstitutionEdit />} />
              <Route path="/institutions/:id/rack_types" element={<RackTypes />} />
              <Route path="/institutions/:id/containers" element={<Containers />} />
              <Route path="/institutions/:id/users" element={<Users />} />
              <Route path="/institutions/:id/racks" element={<Racks />} />
              <Route path="/institutions/:id/racks/:rackId" element={<RackView />} />
              <Route path="/institutions/:id/racks/:rackId/specimens/:specimenId" element={<Specimens />} />
              <Route path="/specimens/:specimenId" element={<Specimens />} />
              <Route path="/institutions/:id/usage" element={<Usage />} />
              <Route path="/usage" element={<Usage />} />
              <Route path="/locked-racks" element={<LockedRacks />} />
              <Route path="/about" element={<About />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/activity" element={<Activity />} />
            </Routes>
          </Suspense>
        </div>
      </Layout>
    </Router>
  );
}

export default App;

