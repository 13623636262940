import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

function Layout({ children }) {
  const location = useLocation();
  
  const getPageTitle = () => {
    console.log('location.pathname', location.pathname);
    switch (location.pathname.split('/')[1]) {
      case '':
        return 'Home';
      case 'locked-racks':
        return 'Manage Locked Racks';
      case 'institutions':
        return 'Manage Institutions';
      default:
        return 'Home';
    }
  };

  useEffect(() => {
    document.title = `${getPageTitle()} | Tubetracker Admin`;
  }, [location]);

  return <>{children}</>;
}

export default Layout; 